<template>
    <div class="add-requisition position-relative">
		<div class="px-20 pt-20 pb-10 mb-20 bg-white border-bottom border-brd-primary position-relative">
			<div class="page-header d-flex align-content-center align-items-center">
				<h1 v-if="this.requisition_id" class="page-title mb-0 me-auto" v-text="this.title['edit']" />
				<h1 v-else class="page-title mb-0 me-auto" v-text="this.title['default']" />
				<a href="javascript:{}"
				   class="d-flex flex-row align-content-center ml-auto ms-auto me-0"
				   @click="this.goBack()">
					<span aria-label="Вернуться назад"
						  class="btn btn-outline-secondary bg-light-gray me-10 border border-brd-primary rounded-3"
						  style="padding: 0.75rem 0.95rem;">
						<img :src="require('@/assets/icons/undo.svg')" alt="Назад" width="18" height="18">
					</span>
					<span class="my-auto text-font-light">Назад</span>
				</a>
			</div>
			<div v-if="this.is_loading_process" class="d-flex vh-60 my-0 align-items-center">
				<Loader />
			</div>
			<form v-else class="border border-brd-primary border-start-0 border-end-0 border-bottom-0 py-4 mt-3 needs-validation"
				  :data-requisition-id="this.requisition_id" :data-client-id="this.client_id" novalidate>

				<div v-if="this.user_cant_edit" class="alert alert-danger">
					<img :src="require('@/assets/icons/warning.svg')" class="d-inline-block me-1" alt="Ошибка" width="14" height="14" style="margin-top: -3px" />
					Недостаточно прав для редактирования данной Заявки.
				</div>

				<div class="px-0">

					<FormSteps :formSteps="this.form_steps"
							   :formStep="this.form_step"
							   @setStep="(step) => this.form_step = step" />

					<!-- Общие -->
					<div v-if="this.form_step === 1" class="mb-20">
						<InputSelect inputId="type_id"
									 inputLabel="Тип заявки:"
									 inputName="type_id"
									 ref="type_id"
									 :inputOptions="this.types_list"
									 :inputValue="(!this.isEmpty(this.type_id)) ? this.type_id : null"
									 inputClass="rounded-1"
									 inputLabelClass="text-font-secondary mb-3"
									 inputUsePlaceholder="false"
									 inputRequired="true"
									 inputWrapClass="flex-column mb-3"
									 @setValue="(value) => this.setAndValidate('type_id', value)" />

						<div v-if="(this.user_can.autosearch.edit_search || this.user_can.autosearch.edit_reverse) && (this.inArray(this.item.type_id, [1, 4]) || this.inArray(this.item.heir_type_id, [1, 4]))" class="d-flex align-items-center mb-20">
							<img :src="require('@/assets/icons/options-icon.svg')" alt="Параметры автопоиска" width="24" height="24" class="me-1">
							<button type="button"
									class="btn btn-outline crm-underline text-font-secondary rounded-3"
									data-bs-toggle="modal"
									data-bs-target="#autoSearchEditModal"
									:data-bs-asfilter-id="this.item.autosearch_id"
									:data-bs-requisition-type-id="this.type_id">
								Редактировать параметры автопоиска
							</button>
						</div>

						<SelectBox inputId="client_id"
								   inputLabel="Клиент:"
								   inputName="client_id"
								   ref="client_id"
								   inputClass="rounded-1 p-2"
								   inputWrapClass="flex-column mb-3"
								   :inputOptions="this.clients_list"
								   :inputValue="(!this.isEmpty(this.item.client_id)) ? this.item.client_id : null"
								   inputSearch="true"
								   inputMultiple="false"
								   inputPlaceholder="Выберите клиента"
								   inputLazyLoad="true"
								   inputRequired="true"
								   @searchInput="(term) => this.search_query = term"
								   @endOfList="(length) => this.clients_offset = length"
								   @setValue="(value) => this.setAndValidate('client_id', value)" />

						<div v-if="this.is_client_edit" class="d-flex align-items-center mb-0">
							<InputText
								inputId="client_name"
								inputLabel="Новое имя клиента:"
								inputName="client_name"
								inputClass="rounded-1 mb-10"
								:inputValue="this.client_name ?? null"
								inputWrapClass="flex-column mb-0"
								:inputSave="(this.is_client_edit)"
								@setValue="(value) => this.client_name = value"
								@saveValue="(value) => this.setNewClientName(this.client_id, value)" />
						</div>
						<div v-if="!this.is_client_edit && !this.isEmpty(this.client_name)" class="d-flex align-items-center mb-20">
							<img :src="require('@/assets/icons/pen-icon.svg')" alt="Этап воронки" width="22" height="22" class="me-1">
							<button type="button" class="btn btn-outline crm-underline text-font-secondary rounded-3"
									@click="this.is_client_edit = !this.is_client_edit">
								Изменить имя клиента
							</button>
						</div>
						<div v-else-if="!this.isEmpty(this.client_name)" class="d-flex align-items-center mb-20">
							<img :src="require('@/assets/icons/pen-icon.svg')" alt="Этап воронки" width="22" height="22" class="me-1">
							<button type="button" class="btn btn-outline crm-underline text-font-secondary rounded-3"
									@click="this.is_client_edit = !this.is_client_edit">
								Отменить редактирование
							</button>
						</div>

						<div class="d-flex align-items-center mb-20">
							<a href="javascript:{}"
							   class="btn d-flex align-items-center p-0 mb-2 me-20 rounded-pill"
							   style="min-width: 150px;"
							   @click.stop.prevent="this.toRoute((!this.isEmpty(this.item.id)) ? '/add-client?return=/edit-requisition&requisition_id='+this.item.id : '/add-client?return=/add-requisition')">
										<span class=" d-flex align-items-center justify-content-center bg-primary rounded-circle me-1"
											  style="width: 40px; height: 40px;">
											<img :src="require('@/assets/icons/add-icon.svg')"
												 class="d-block"
												 alt="Добавить"
												 width="14"
												 height="14">
										</span>
								<span class="text-font-dark fw-semi font-semi fs-4 px-2">Добавить клиента</span>
							</a>
						</div>

						<InputText
							ref="name"
							inputId="name"
							inputLabel="Название заявки:"
							inputName="name"
							inputClass="rounded-1 mb-10"
							:inputValue="(!this.isEmpty(this.item.name)) ? this.item.name : null"
							inputWrapClass="flex-column mb-3"
							inputRequired="true"
							@setValue="(value) => this.setAndValidate('name', value)" />

						<div class="d-flex flex-column mb-10">
							<span class="text-font-secondary mb-3">Категория клиента:</span>
							<ul class="d-flex check-group flex-wrap align-items-center m-0 p-0">
								<li class="me-20 mb-10">
									<input type="radio"
										   class="btn-check"
										   name="is_hot"
										   id="is_hot"
										   autocomplete="off"
										   :checked="this.item.is_hot === 1 || Boolean(this.item.is_hot)"
										   @click="(event) => this.setAndValidate('is_hot', 1)">
									<label class="d-flex align-items-center justify-content-center btn border border-fire btn-lg rounded-circle"
										   style="width: 50px; height: 50px;"
										   for="is_hot">
										<img :src="require('@/assets/icons/fire-icon.svg')"
											 class="d-block"
											 alt="Hot"
											 width="28"
											 height="28" />
									</label>
								</li>
								<li class="me-20 mb-10">
									<input type="radio"
										   class="btn-check"
										   name="priority"
										   id="priority_1"
										   autocomplete="off"
										   :checked="this.item.priority === 1"
										   @click="(event) => this.setAndValidate('priority', 1)">
									<label class="btn btn-raspberry btn-lg rounded-circle"
										   style="width: 50px; height: 50px;"
										   for="priority_1" />
								</li>
								<li class="me-20 mb-10">
									<input type="radio"
										   class="btn-check"
										   name="priority"
										   id="priority_2"
										   autocomplete="off"
										   :checked="this.item.priority === 2"
										   @click="(event) => this.setAndValidate('priority', 2)">
									<label class="btn btn-warning btn-lg rounded-circle"
										   style="width: 50px; height: 50px;"
										   for="priority_2" />
								</li>
								<li class="me-20 mb-10">
									<input type="radio"
										   class="btn-check"
										   name="priority"
										   id="priority_3"
										   autocomplete="off"
										   :checked="this.item.priority === 3"
										   @click="(event) => this.setAndValidate('priority', 3)">
									<label class="btn btn-ocean btn-lg rounded-circle"
										   style="width: 50px; height: 50px;"
										   for="priority_3" />
								</li>
								<li class="me-20 mb-10">
									<input type="radio"
										   class="btn-check"
										   name="priority"
										   id="priority_4"
										   autocomplete="off"
										   :checked="this.item.priority === 4"
										   @click="(event) => this.setAndValidate('priority', 4)">
									<label class="btn btn-primary btn-lg rounded-circle"
										   style="width: 50px; height: 50px;"
										   for="priority_4" />
								</li>
							</ul>
						</div>

						<InputSelect inputId="funnel_id"
									 inputName="funnel_id"
									 ref="funnel_id"
									 inputLabel="Воронка:"
									 inputLabelClass="text-font-secondary mb-3"
									 inputClass="rounded-3 p-2"
									 inputWrapClass="mb-20"
									 :inputValue="this.item.funnel_id"
									 :inputOptions="this.funnels_list"
									 :inputRequired="true"
									 :inputUsePlaceholder="false"
									 :inputPlaceholder="false"
									 @setValue="(value) => this.setAndValidate('funnel_id', value)" />

						<!--                    <div class="d-flex align-items-center mb-20">
												<button
													type="button"
													class="crm-btn btn border border-brd-primary bg-light-gray rounded-3 me-10"
												>
													<img :src="require('@/assets/icons/btn-arrow-icon.svg')" alt="Этап воронки" width="20" height="20">
												</button>

												<div class="d-flex flex-column">
													<span class="text-font-secondary fs-4">Этап воронки:</span>
													<span>Новый</span>
												</div>
											</div>-->

						<SelectBox v-if="this.inArray(this.item.type_id, [2, 3, 4]) || this.inArray(this.item.heir_type_id, [2, 3, 4])"
								   inputId="object"
								   inputName="object"
								   ref="object"
								   inputLabel="Объект:"
								   :inputOptions="this.objects_list"
								   :inputValue="this.item.object_id"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputSearch="true"
								   inputMultiple="false"
								   inputPlaceholder="Выберите объект"
								   inputLazyLoad="true"
								   inputRequired="true"
								   @searchInput="(term) => this.objects_search_query = term"
								   @endOfList="(length) => this.objects_offset = length"
								   @setValue="(value) => this.setAndValidate('object_id', value)" />

						<InputText v-if="this.item.type_id == 2 || this.item.heir_type_id == 2"
							inputId="prep_price"
							inputLabel="Предварительная цена:"
							inputName="prep_price"
							ref="prep_price"
							inputClass="rounded-1 mb-10"
							:inputValue="(!this.isEmpty(this.item.prep_price)) ? this.item.prep_price : null"
							inputWrapClass="flex-column mb-3"
							@setValue="(value) => this.setAndValidate('prep_price', value)" />

						<InputText v-if="this.item.type_id == 2 || this.item.heir_type_id == 2"
							inputId="start_price"
							inputLabel="Начальная цена:"
							inputName="start_price"
							ref="start_price"
							inputClass="rounded-1 mb-10"
							:inputValue="(!this.isEmpty(this.item.start_price)) ? this.item.start_price : null"
							inputWrapClass="flex-column mb-3"
							@setValue="(value) => this.setAndValidate('start_price', value)" />

						<InputText v-if="this.item.type_id == 2 || this.item.heir_type_id == 2"
							inputId="fact_price"
							inputLabel="Фактическая цена:"
							inputName="fact_price"
							ref="fact_price"
							inputClass="rounded-1 mb-10"
							:inputValue="(!this.isEmpty(this.item.fact_price)) ? this.item.fact_price : null"
							inputWrapClass="flex-column mb-3"
							@setValue="(value) => this.setAndValidate('fact_price', value)" />

						<SelectBox inputId="tags"
								   inputName="tags"
								   ref="tags"
								   inputLabel="Теги:"
								   :inputOptions="this.tags_list"
								   :inputValue="this.item.tags"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputMultiple="true"
								   inputSearch="true"
								   @setValue="(value) => this.setAndValidate('tags', value)" />

						<div class="row align-items-center flex-row mb-2">
							<div class="row-fluid mb-2">
								<div class="col col-4">
									<label for="contracts_list" class="form-label col text-dark-gray">
										Договоры:
									</label>
								</div>
								<div id="contracts_list" class="col col-8">
									<ul v-if="!this.isEmpty(this.item.contracts)"
										class="list-unstyled d-flex flex-column">
										<li v-for="contract in this.item.contracts" :key="contract.id"
											class="d-inline-block mb-2">
											<a href="javascript:{}"
											   class="link fs-4"
											   data-bs-toggle="modal"
											   data-bs-target="#addEditContractModal"
											   :data-bs-contract-id="contract.id"
											   :data-bs-source-id="item.id"
											   :data-bs-section="'clients'">
												<img :src="require('@/assets/icons/pen-icon.svg')" alt="Edit" class="d-inline-flex ms-0 me-1" width="14" height="14" style="margin-top: 1px;">
												{{ this.getContractType(contract.type) }}
												"{{ contract.number }}"
												с {{ this.formatDate('DD.MM.YYYY', contract.date_start) }}
												по {{ this.formatDate('DD.MM.YYYY', contract.date_end) }}
											</a>
										</li>
									</ul>
								</div>

								<a href="javascript:{}"
								   class="btn d-flex align-items-center p-0 mt-1 mb-2 me-20 rounded-pill"
								   style="min-width: 150px;"
								   data-bs-toggle="modal"
								   data-bs-target="#addEditContractModal"
								   :data-bs-contract-id="null"
								   :data-bs-source-id="item.id"
								   :data-bs-section="'requisitions'">
										<span class=" d-flex align-items-center justify-content-center bg-primary rounded-circle me-1"
											  style="width: 40px; height: 40px;">
											<img :src="require('@/assets/icons/add-icon.svg')"
												 class="d-block"
												 alt="Добавить"
												 width="14"
												 height="14">
										</span>
									<span class="text-font-dark fw-semi font-semi fs-4 px-2">Добавить договор</span>
								</a>
							</div>
						</div>

						<TextArea inputId="description"
								  inputName="description"
								  ref="description"
								  inputLabel="Описание:"
								  :inputValue="this.item.description"
								  inputLabelClass="text-font-secondary mb-3"
								  inputClass="rounded-3 p-2"
								  inputWrapClass="mb-20"
								  @setValue="(value) => this.setAndValidate('description', value)" />

						<!-- Кастомные поля -->
						<div v-if="!this.isEmpty(this.fields_list)" class="">
							<div v-for="(field, key) in this.fields_list" :key="field.value">

								<SelectBox v-if="!this.isEmpty(field.is_sub_group)"
										   :inputId="'fields_' + key"
										   :inputName="'fields_' + key"
										   :ref="'fields.' + key"
										   :inputLabel="field.label + ':'"
										   :inputValue="(!this.isEmpty(this.getCustomFieldValue(key))) ? this.getCustomFieldValue(key) : null"
										   :inputOptions="(!this.isEmpty(field.params.items)) ? Object.values(field.params.items) : null"
										   :inputRequired="field.is_required"
										   inputMultiple="false"
										   inputLabelClass="text-font-secondary mb-3"
										   inputClass="rounded-3 p-2"
										   inputWrapClass="mb-20"
										   @setValue="(value) => this.setAndValidate('fields.' + key, value)" />

								<div v-if="!this.isEmpty(field.is_sub_group)">

									<div v-for="(group, key2) in field.params.items" :key="group.value">
										<div v-if="(this.isEmpty(field.requisition_type) || this.inArray(this.item.type_id, field.requisition_type)) && (group.is_active || this.inArray(group.value, this.getCustomFieldValue(key)))">

											<InputText v-if="group.type == 0"
													   :inputLabel="group.name + ':'"
													   :inputValue=" this.item.fields && (!this.isEmpty(this.item.fields[key2])) ? this.item.fields[key2] : null"
													   :inputRequired="group.is_required"
													   inputLabelClass="text-font-secondary mb-3"
													   inputClass="rounded-3 p-2"
													   inputWrapClass="mb-20"
													   @setValue="(value) => this.setAndValidate('fields.' + key2, value)" />

											<InputNumber v-else-if="group.type == 1"
														 :inputLabel="group.name + ':'"
														 :inputValue=" this.item.fields && (!this.isEmpty(this.item.fields[key2])) ? this.item.fields[key2] : null"
														 :inputRequired="group.is_required"
														 inputLabelClass="text-font-secondary mb-3"
														 inputClass="rounded-3 p-2"
														 inputWrapClass="mb-20"
														 @setValue="(value) => this.setAndValidate('fields.' + key2, value)" />

											<SelectBox v-else-if="group.type == 2 || group.type == 3"
													   :inputLabel="group.name + ':'"
													   :inputValue="this.item.fields && (!this.isEmpty(this.item.fields[key2])) ? this.item.fields[key2] : null"
													   :inputRequired="group.is_required"
													   :inputOptions="group.options"
													   :inputMultiple="(group.type == 3)"
													   inputLabelClass="text-font-secondary mb-3"
													   inputClass="rounded-3 p-2"
													   inputWrapClass="mb-20"
													   @setValue="(value) => this.setAndValidate('fields.' + key2, value)" />

											<Switcher v-else-if="group.type == 4"
													  :inputLabel="group.name"
													  :inputValue="this.item.fields && (!this.isEmpty(this.item.fields[key2])) ? this.item.fields[key2] : null"
													  :inputRequired="group.is_required"
													  inputClass="px-0"
													  inputWrapClass="flex-column mb-3"
													  @setValue="(value) => this.setAndValidate('fields.' + key2, value)" />

											<TextArea v-else-if="group.type == 5"
													  :inputLabel="group.name + ':'"
													  :inputValue="this.item.fields && (!this.isEmpty(this.item.fields[key2])) ? this.item.fields[key2] : null"
													  :inputRequired="group.is_required"
													  inputLabelClass="text-font-secondary mb-3"
													  inputClass="rounded-3 p-2"
													  inputWrapClass="mb-20"
													  @setValue="(value) => this.setAndValidate('fields.' + key2, value)" />

										</div>
									</div>
								</div>

								<div v-else-if="(this.isEmpty(field.requisition_type) || this.inArray(this.item.type_id, field.requisition_type))">

									<InputText v-if="field.type == 0"
											   :inputId="'fields_' + key"
											   :inputName="'fields_' + key"
											   :ref="'fields.' + key"
											   :inputLabel="field.label + ':'"
											   :inputValue="(!this.isEmpty(this.getCustomFieldValue(key))) ? this.getCustomFieldValue(key) : null"
											   :inputRequired="field.is_required"
											   inputLabelClass="text-font-secondary mb-3"
											   inputClass="rounded-3 p-2"
											   inputWrapClass="mb-20"
											   @setValue="(value) => this.setAndValidate('fields.' + key, value)" />

									<InputNumber v-else-if="field.type == 1"
												 :inputId="'fields_' + key"
												 :inputName="'fields_' + key"
												 :ref="'fields.' + key"
												 :inputLabel="field.label + ':'"
												 :inputValue="(!this.isEmpty(this.getCustomFieldValue(key))) ? this.getCustomFieldValue(key) : null"
												 :inputRequired="field.is_required"
												 inputLabelClass="text-font-secondary mb-3"
												 inputClass="rounded-3 p-2"
												 inputWrapClass="mb-20"
												 @setValue="(value) => this.setAndValidate('fields.' + key, parseInt(value))" />

									<SelectBox v-else-if="field.type == 2 || field.type == 3"
											   :inputId="'fields_' + key"
											   :inputName="'fields_' + key"
											   :ref="'fields.' + key"
											   :inputLabel="field.label + ':'"
											   :inputValue="(!this.isEmpty(this.getCustomFieldValue(key))) ? this.getCustomFieldValue(key) : null"
											   :inputRequired="field.is_required"
											   :inputOptions="(!this.isEmpty(field.params.items)) ? field.params.items : null"
											   :inputMultiple="(field.type == 3)"
											   inputLabelClass="text-font-secondary mb-3"
											   inputClass="rounded-3 p-2"
											   inputWrapClass="mb-20"
											   @setValue="(value) => this.setAndValidate('fields.' + key, value)" />

									<Switcher v-else-if="field.type == 4"
											  :inputId="'fields_' + key"
											  :inputName="'fields_' + key"
											  :ref="'fields.' + key"
											  :inputLabel="field.label"
											  :inputValue="(!this.isEmpty(this.getCustomFieldValue(key))) ? this.getCustomFieldValue(key) : null"
											  :inputRequired="field.is_required"
											  inputClass="px-0"
											  inputWrapClass="flex-column mb-3"
											  @setValue="(value) => this.setAndValidate('fields.' + key, parseInt(value))" />

									<TextArea v-else-if="field.type == 5"
											  :inputId="'fields_' + key"
											  :inputName="'fields_' + key"
											  :ref="'fields.' + key"
											  :inputLabel="field.label + ':'"
											  :inputValue="(!this.isEmpty(this.getCustomFieldValue(key))) ? this.getCustomFieldValue(key) : null"
											  :inputRequired="field.is_required"
											  inputLabelClass="text-font-secondary mb-3"
											  inputClass="rounded-3 p-2"
											  inputWrapClass="mb-20"
											  @setValue="(value) => this.setAndValidate('fields.modelp_' + key, value)" />

								</div>
							</div>
						</div>

						<SelectBox inputId="employee_id"
								   inputName="employee_id"
								   ref="employee_id"
								   inputLabel="Ответственный:"
								   :inputOptions="this.employees_list"
								   :inputValue="this.item.employee_id"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputSearch="true"
								   @setValue="(value) => this.setAndValidate('employee_id', value)" />
						
						<SelectBox v-if="this.item.source_see == 1" inputId="source_id"
								   inputName="source_id"
								   ref="source_id"
								   inputLabel="Источник:"
								   :inputOptions="this.sources_list"
								   :inputValue="this.item.source_id"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputSearch="true"
								   :inputRequired="this.required_source"
								   @setValue="(value) => this.setAndValidate('source_id', value)" />

						<SelectBox v-if="this.$store.getters.userInfo.agency_id != 19895" inputId="partner_id"
								   inputName="partner_id"
								   ref="partner_id"
								   inputLabel="Партнер:"
								   :inputOptions="this.partners_list"
								   :inputValue="this.item.partner_id"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputSearch="true"
								   @setValue="(value) => this.setAndValidate('partner_id', value)" />

						<Switcher v-if="(this.item.type_id == 4 || this.item.heir_type_id == 4)" inputId="autosearch_enabled"
								  inputName="autosearch_enabled"
								  ref="autosearch_enabled"
								  inputLabel="Включить автопоиск по параметрам объекта"
								  :inputValue="this.item.autosearch_enabled"
								  inputClass="px-0 mb-3"
								  inputWrapClass="flex-column mb-3"
								  @setValue="(value) => this.setAndValidate('autosearch_enabled', value)" />

					</div>

					<!-- Объекты -->
					<div v-if="!this.isEmpty(this.item.object_id) && this.form_step === 2" class="mb-20">
						<ObjectsList :isGlobalSearch="false"
									 currentSection="requisitions"
									 :inputFilters="{ objects_ids: [this.item.object_id] }"
									 layout="short" />
					</div>
					<div v-else-if="this.form_step === 2" class="mb-20">
						<EmptyList message="Объекты не найдены" />
					</div>

					<!-- Параметры автопоиска -->
					<div v-if="!this.isEmpty(this.item.autosearch.filter) && this.form_step === 3" class="mb-20">
						<ul class="flex-column p-0 mb-20">
							<li v-for="(field, field_id, i) of this.item.autosearch.filter"
								:key="'field_' + i"
								class="border-top border-brd-primary py-3"
								:class="(i === this.item.autosearch.filter.length - 1) ? 'border-bottom' : null">
								<div class="d-flex align-items-center">
									<div v-if="this.item.autosearch.filter[field_id]['is_edited'] === true && !this.isEmpty(this.item.autosearch.filter[field_id]['input'])" class="d-flex flex-column w-100 me-2">
										<div v-if="this.item.autosearch.filter[field_id]['input'].type == 'text'"
											 class="col w-100">
											<InputText :inputId="this.item.autosearch.filter[field_id]['input'].id"
													   :inputName="this.item.autosearch.filter[field_id]['input'].name"
													   :ref="this.item.autosearch.filter[field_id]['input'].name"
													   :inputLabel="this.item.autosearch.filter[field_id]['input'].label + ':'"
													   :inputValue="this.item.autosearch.filter[field_id]['input'].value"
													   inputLabelClass="text-font-secondary mb-2"
													   inputClass="rounded-3 p-2"
													   inputWrapClass="mb-0"
													   @setValue="value => this.item.autosearch.filter[field_id]['input'].value = value" />
										</div>
										<div v-else-if="this.item.autosearch.filter[field_id]['input'].type == 'number'"
											 class="col w-100">
											<div v-if="this.item.autosearch.filter[field_id]['input'].is_range">

												<InputNumber :inputId="this.item.autosearch.filter[field_id]['input'].id + '_from'"
															 :inputName="this.item.autosearch.filter[field_id]['input'].name + '_from'"
															 :inputLabel="this.item.autosearch.filter[field_id]['input'].label + ', от:'"
															 :inputValue="this.item.autosearch.filter[field_id]['input'].value['from']"
															 inputLabelClass="text-font-secondary mb-2"
															 inputClass="rounded-3 p-2"
															 inputWrapClass="mb-0"
															 @setValue="value => this.item.autosearch.filter[field_id]['input'].value['from'] = value" />

												<InputNumber :inputId="this.item.autosearch.filter[field_id]['input'].id + '_to'"
															 :inputName="this.item.autosearch.filter[field_id]['input'].name + '_to'"
															 :inputLabel="this.item.autosearch.filter[field_id]['input'].label + ', до:'"
															 :inputValue="this.item.autosearch.filter[field_id]['input'].value['to']"
															 inputLabelClass="text-font-secondary mb-2"
															 inputClass="rounded-3 p-2"
															 inputWrapClass="mb-0"
															 @setValue="value => this.item.autosearch.filter[field_id]['input'].value['to'] = value" />

											</div>
											<div v-else>
												<InputNumber :inputId="this.item.autosearch.filter[field_id]['input'].id"
															 :inputName="this.item.autosearch.filter[field_id]['input'].name"
															 :inputLabel="this.item.autosearch.filter[field_id]['input'].label + ':'"
															 :inputValue="this.item.autosearch.filter[field_id]['input'].value"
															 inputLabelClass="text-font-secondary mb-2"
															 inputClass="rounded-3 p-2"
															 inputWrapClass="mb-0"
															 @setValue="value => this.item.autosearch.filter[field_id]['input'].value = value" />
											</div>
										</div>
										<div v-else-if="this.item.autosearch.filter[field_id]['input'].type == 'checkbox'"
											 class="col w-100">
											<Switcher :inputId="this.item.autosearch.filter[field_id]['input'].id"
													  :inputName="this.item.autosearch.filter[field_id]['input'].name"
													  :inputLabel="this.item.autosearch.filter[field_id]['input'].label + ':'"
													  :inputValue="this.item.autosearch.filter[field_id]['input'].value"
													  inputLabelClass="text-font-secondary mb-2"
													  inputClass="rounded-3 p-2"
													  inputWrapClass="mb-0"
													  @setValue="value => this.item.autosearch.filter[field_id]['input'].value = value" />
										</div>
										<div v-else-if="this.item.autosearch.filter[field_id]['input'].type == 'select'"
											 class="col w-100">
											<SelectBox :inputId="this.item.autosearch.filter[field_id]['input'].id"
													   :inputName="this.item.autosearch.filter[field_id]['input'].name"
													   :ref="this.item.autosearch.filter[field_id]['input'].name"
													   :inputLabel="this.item.autosearch.filter[field_id]['input'].label + ':'"
													   :inputValue="this.item.autosearch.filter[field_id]['input'].value"
													   :inputOptions="this.item.autosearch.filter[field_id]['input'].options"
													   :inputMultiple="this.item.autosearch.filter[field_id]['input'].is_multiple"
													   inputLabelClass="text-font-secondary mb-2"
													   inputClass="rounded-3 p-2"
													   inputWrapClass="mb-0"
													   inputSearch="true"
													   @setValue="value => this.item.autosearch.filter[field_id]['input'].value = value" />
										</div>
										<div v-else-if="this.item.autosearch.filter[field_id]['input'].type == 'date'"
											 class="col w-100">
											<DateTimePicker :inputId="this.item.autosearch.filter[field_id]['input'].id"
															:inputName="this.item.autosearch.filter[field_id]['input'].name"
															:ref="this.item.autosearch.filter[field_id]['input'].name"
															:inputLabel="this.item.autosearch.filter[field_id]['input'].label + ':'"
															:inputValue="this.item.autosearch.filter[field_id]['input'].value"
															inputLabelClass="text-font-secondary mb-2"
															inputClass="rounded-3 p-2"
															inputWrapClass="mb-0"
															displayFormat="DD.MM.YYYY"
															@setValue="value => this.item.autosearch.filter[field_id]['input'].value = value" />
										</div>
										<div v-else-if="this.item.autosearch.filter[field_id]['input'].type == 'textarea'"
											 class="col w-100">
											<TextArea :inputId="this.item.autosearch.filter[field_id]['input'].id"
													  :inputName="this.item.autosearch.filter[field_id]['input'].name"
													  :ref="this.item.autosearch.filter[field_id]['input'].name"
													  :inputLabel="this.item.autosearch.filter[field_id]['input'].label + ':'"
													  :inputValue="this.item.autosearch.filter[field_id]['input'].value"
													  inputLabelClass="text-font-secondary mb-2"
													  inputClass="rounded-3 p-2"
													  inputWrapClass="mb-0"
													  @setValue="value => this.item.autosearch.filter[field_id]['input'].value = value" />
										</div>
									</div>
									<div v-else class="d-flex flex-column me-auto">
										<span class="mb-10">{{ field.label }}</span>
										<span class="text-font-secondary">{{ field.value }}</span>
									</div>
									<div v-if="this.item.autosearch.filter[field_id]['is_edited'] === true" class="d-flex my-auto" style="padding-top: 35px;">
										<button type="button"
												class="crm-btn btn btn-outline bg-light-gray rounded-circle me-10"
												aria-label="Сохранить"
												@click="this.saveFieldInput(this.item.autosearch_id, field_id, this.item.autosearch.filter[field_id]['input'].value)">
											<img :src="require('@/assets/icons/save-icon.svg')" alt="Сохранить" width="20" height="20" class="d-block">
										</button>
										<button type="button"
												class="crm-btn btn btn-outline bg-light-gray rounded-circle me-10"
												aria-label="Отменить"
												@click="this.item.autosearch.filter[field_id]['is_edited'] = false">
											<img :src="require('@/assets/icons/close-icon.svg')" alt="Отменить" width="16" height="16" class="d-block">
										</button>
									</div>
									<div v-else class="d-flex mt-3">
										<button type="button"
												class="crm-btn btn btn-outline bg-light-gray rounded-circle me-10"
												aria-label="Редактировать"
												@click="this.getFieldInput(this.item.autosearch_id, field_id)">
											<img :src="require('@/assets/icons/pen-icon.svg')" alt="Редактировать" width="20" height="20" class="d-block">
										</button>
										<button type="button"
												class="crm-btn btn btn-outline bg-light-gray rounded-circle me-10"
												aria-label="Удалить"
												@click="this.deleteFieldInput(this.item.autosearch_id, field_id)">
											<img :src="require('@/assets/icons/basket-icon.svg')" alt="Удалить" width="20" height="20" class="d-block">
										</button>
									</div>
								</div>
							</li>
						</ul>
						<div class="d-flex align-items-center mb-20">
							<img :src="require('@/assets/icons/info-icon.svg')" alt="Этап воронки" width="20" height="20" class="me-2">
							<span class="text-font-light fs-5">Обратите внимание, что отредактированные, но не сохраненные параметры не сохраняются по кнопке «Сохранить»</span>
						</div>
					</div>
					<div v-else-if="this.form_step === 3" class="mb-20">
						<EmptyList message="Параметры не найдены" />
					</div>
				</div>

				<button
					type="button"
					class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
					@click="this.deBounce(this.addEditRequisition(), 1000)"
					v-text="(this.item.id) ? 'Сохранить' : 'Добавить'" />

			</form>
		</div>
	</div>

	<ConfirmModal id="ConfirmGoBack"
				  ref="confirmGoBack"
				  title="Подтвердите действие"
				  text="У вас есть несохранённые изменения. Вы уверены, что хотите отменить редактирование!?"
				  fromSection="addEditRequisition"
				  @confirmed="this.goBack(true)"
				  btnText="Да" />

	<AddEditContractModal @contractAdded="(data) => { if (!this.isEmpty(data.contract)) this.item.contracts.push(data.contract) }" />

	<AutoSearchEditModal />

	<SetTagsModal @saveSuccess="(tags_ids, source_id) => this.setRequisitionTags(tags_ids, source_id)" />

	<ResultsModal id="addEditRequisition"
				  :state="this.resultsModalState.state"
				  :title="this.resultsModalState.title"
				  :message="this.resultsModalState.message"
				  :successButton="(!this.isEmpty(this.object_id) || this.$props.returnPath == '/add-object') ? 'Продолжить' : 'К списку заявок'"
				  :successAction="() => this.goBack()" />

</template>

<script>
    import CommonService from "../services/CommonService";
	import ConfirmModal from "@/components/modals/ConfirmModal";
	import SelectBox from "@/components/inputs/SelectBox";
	import InputText from "@/components/inputs/InputText";
	import TextArea from "@/components/inputs/TextArea";
	import Switcher from "@/components/inputs/Switcher";
	import api from "@/api";
	import * as bootstrap from "bootstrap";
	import FormSteps from "@/components/common/FormSteps";
	import ObjectsList from "@/components/objects/ObjectsList";
	import AddEditContractModal from "@/components/modals/AddEditContractModal";
	import ResultsModal from "@/components/modals/ResultsModal";
	import Loader from "@/components/common/Loader";
	import InputNumber from "@/components/inputs/InputNumber";
	//import InnerFieldsForm from "@/components/forms/InnerFieldsForm";
	import EmptyList from "@/components/common/EmptyList";
	//import DateTimePicker from "@/components/inputs/DateTimePicker";
	import InputSelect from "@/components/inputs/InputSelect";
	import DateTimePicker from "@/components/inputs/DateTimePicker";
	import AutoSearchEditModal from "@/components/modals/AutoSearchEditModal";
	import SetTagsModal from "@/components/modals/SetTagsModal";
	import {CommonDataService} from "@/services/CommonDataService";
	//import StageBar from "@/components/inputs/StageBar";

    export default {
        name: "addEditRequisition",
		props: {
			returnPath: {type: String},
			typeId: {type: [ String, Number ]},
			requisitionId: {type: [ String, Number ]},
			clientId: {type: [ String, Number ]},
			objectId: {type: [ String, Number ]},
			funnelId: {type: [ String, Number ]},
			restoreForm: {type: [ Boolean ]},
		},
		components: {
			SetTagsModal,
			AutoSearchEditModal,
			DateTimePicker,
			InputSelect,
			//DateTimePicker,
			EmptyList,
			//InnerFieldsForm,
			InputNumber,
			Loader,
			//StageBar,
			ResultsModal,
			AddEditContractModal,
			ObjectsList,
			FormSteps,
			SelectBox,
			InputText,
			TextArea,
			Switcher,
			ConfirmModal
		},
        data() {
            return {
				old_employee_id: null,
				old_is_confirm: null,
                title: {
					default: "Новая заявка",
					edit: "Редактирование заявки",
				},
				form_step: 1,
				/*form_steps: [
					{
						id: 1,
						title: 'Общие',
						icon: null,
					}, {
						id: 2,
						title: 'Объекты',
						icon: null,
					}, {
						id: 3,
						title: 'Параметры автопоиска',
						icon: null,
					},
				],*/
				//type_id: (typeof this.typeId !== "undefined" && this.typeId) ? this.typeId : null,
				requisition_id: (typeof this.requisitionId !== "undefined" && this.requisitionId) ? this.requisitionId : null,
				client_id: (typeof this.clientId !== "undefined" && this.clientId) ? this.clientId : null,
				object_id: (typeof this.objectId !== "undefined" && this.objectId) ? this.objectId : null,
				funnel_id: (typeof this.funnelId !== "undefined" && this.funnelId) ? this.funnelId : null,
				restore_form: (typeof this.restoreForm !== "undefined" && this.restoreForm),
				item: {
					id: null,
					type_id: null,
					heir_type_id: null,
					client_id: null,
					contracts: [],
					fields: [],
					autosearch_id: null,
					autosearch: {
						filter: [],
						is_active: 0,
						is_search: 0,
						is_reverse: 0,
					},
					autosearch_enabled: false,
					source_id: null,
					source_see: 1
				},
				//client: null,
				funnels_list: [],
				types_list: [],
				sources_list: [],
				partners_list: [],
				tags_list: [],
				fields_list: [],
				employees_list: [],
				search_query: '',
				objects_search_query: '',
				clients_offset: 0,
				objects_offset: 0,
				clients_list: [],
				clients_proccessed: false,
				objects_proccessed: false,
				isTagListShowed: false,
				requestEditFormStep: 1,
				requestObjectEditFormStep: 1,
				contract_types: [],
				user_cant_edit: false,
				is_form_changed: false,
				objects_list: [],
				variables_list: [],
				resultsModalState: {
					state: '',
					title: '',
					message: ''
				},
				errors: [],
				is_loading_process: true,
				is_client_edit: false,
				required_type: true,
				required_role: true,
				required_source: false,
            };
        },
		methods: CommonService.mergeRecursive({
			getRequiredTypeRole(){
				
				return api.get('/common/required_type_role', {
					params: {
						funel_id: this.funnelId
					}
				})
				.then((response) => {

					console.log(response.data);

					if (response.status == 200 && response.data.success) {

						let list = response.data;
						
						return list;
					} else {
						return {};
					}
				}).catch(function (error) {

					CommonService.log('error', 'getRequiredTypeRole()::axios', error);

				});
			},
			formatPhone(value) {
				return CommonService.formatPhoneNumber(value);
			},
			alertNote(message) {
				return alert(message);
			},
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			formatDate(format, datetime) {
				return CommonService.formatDateTime(datetime, 'ru', format);
			},
			formatPrice(value, currency, sign) {
				return CommonService.formatPrice(value, currency, sign);
			},
			sortArray(data) {
				return CommonService.sortArray(data);
			},
			formatDateTime(datetime, locale, format) {
				return CommonService.formatDateTime(datetime, locale, format);
			},
			goBack(forced) {
				if (!forced) {
					if (this.is_form_changed && this.isEmpty(this.$props.returnPath)) {
						let confirm = CommonService.getModal('addEditObjectConfirmGoBack');
						confirm.show();
					} else {

						let path = '/requisitions';
						if (!this.isEmpty(this.$props.returnPath))
							path = this.$props.returnPath;

						let query = [];
						if (!this.isEmpty(this.item.funnel_id))
							query.push('funnel_id=' + this.item.funnel_id);

						if (!this.isEmpty(this.client_id))
							query.push('id=' + this.client_id)

						if (!this.isEmpty(this.item.id))
							query.push('object_id=' + this.item.id);

						if (this.inArray(this.$props.returnPath, [
							'/add-object',
							'/edit-object',
							'/add-client',
							'/edit-client'
						])) {
							query.push('restore_form=true');
						}

						if (query.length > 0)
							path = path + '?' + query.join('&');


						if (!this.isEmpty(this.item.id))
							path = path + '#item_' + this.item.id.toString();

						this.$router.push(path);

					}
				} else {
					window.history.back();
				}
			},
			toRoute(url) {
				this.$store.commit('setFormsState', { 'addRequisition': this.item });
				this.$router.push(url);
			},
			deBounce(callback, timeout) {

				if (typeof timeout == "undefined")
					timeout = 1000;

				CommonService.debounce(callback, timeout);
			},
			getClient(client_id) {
				return api.get('/client', {
					params: {
						client_id: client_id,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getClient()::axios', {response: response.data});

					if (response.status == 200 && response.data.success)
						return response.data.item;

					return null;
				}).catch(function (error) {

					CommonService.log('error', 'getClient()::axios', error);

				});
			},
			getObjectsList(objects_ids, with_groups) {

				if (typeof objects_ids == "undefined" && !this.isEmpty(this.item.object_id))
					objects_ids = [this.item.object_id];

				let params = {
					section: 'requisitions',
					offset: this.objects_offset ?? 0,
					search_query: this.objects_search_query,
					objects_ids: objects_ids ?? null
				}

				this.objects_proccessed = true;

				CommonService.log('debug', 'getObjectsList()', params);

				return api.get('/objects/list', {
					params: params
				})
				.then((response) => {

					this.objects_proccessed = false;

					CommonService.log('debug', 'getObjectsList()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						let groups = (response.data.groups) ? response.data.groups : [];

						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, object]) => {

								if (object.group_id && with_groups) {

									let group_id = object.group_id;
									if (groups[group_id]) {

										list.push({
											value: false,
											name: '<b>' + groups[group_id] + '</b>'
										});

										delete groups[group_id];
									}
								}

								if (this.isEmpty(this.objects_list.filter(item => item.value === object.id))) {
									list.push({
										value: object.id,
										name: object.title + ' (ID '+object.id+')<br/><small class="text-secondary">' + object.address + '</small><br/><small class="text-primary">' + this.formatPrice(object.price) + ' руб.</small>'
									});
								}
							});
						}

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getObjectsList()::axios', error);

					this.objects_proccessed = false;
				});
			},
			getRequisition(requisition_id) {
				return api.get('/requisition', {
					params: {
						requisition_id: requisition_id,
						need_autosearch: true,
					}
				}).then((response) => {

					CommonService.log('debug', 'getRequisitions()::axios', {response: response.data});

					if (response.status == 200 && response.data.success)
						return response.data.item;

					return null;
				}).catch(function (error) {

					CommonService.log('error', 'getRequisitions()::axios', error);

				});
			},
			getFunnels() {
				if (this.$store.clients_funnels)
					return this.$store.clients_funnels;
				else
					return api.get('common/funnels', {
						params: {
							section: 'requisitions',
							use_counters: false,
						}
					})
						.then((response) => {

							CommonService.log('debug', 'getFunnels()::axios', {response: response.data});

							if (response.status == 200 && response.data.success) {
								let list = [];
								Object.entries(response.data.list).forEach(([key, option]) => {

									let count = '';
									if (option.count)
										count = ' ('+option.count+')';

									list.push({
										value: option.id,
										name: option.name + count
									});
								});

								this.$store.commit('setFunnelsList', {
									section: 'clients',
									list: list
								});

								return list;
							} else {
								return {};
							}

						}).catch(function (error) {

							CommonService.log('error', 'getFunnels()::axios', error);

						});
			},
			getEmployees() {
				return api.get('/common/employees', {
					params: {
						add_groups: true
					}
				})
					.then((response) => {

						CommonService.log('debug', 'getEmployees()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {

							let list = [];
							let groups = (response.data.groups) ? response.data.groups : [];
							//console.log(groups);

							if (response.data.list) {
								Object.entries(response.data.list).forEach(([key, employee]) => {

									if (employee.group_id) {

										let group_id = employee.group_id;
										if (groups[group_id]) {

											list.push({
												value: false,
												name: '<b>' + groups[group_id] + '</b>'
											});

											delete groups[group_id];
										}
									}

									let department_name = (employee.department)  ? ' (' + employee.department + ')' : '';
									list.push({
										value: employee.id,
										name: (employee.group_id) ? '&nbsp;&nbsp;-&nbsp;' + employee.name : employee.name + department_name
									});
								});
							}

							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getEmployees()::axios', error);

					});
			},
			getFieldsList() {
				return api.get('/fields', {
					params: {
						section: 'requisitions'
					}
				})
					.then((response) => {

						CommonService.log('debug', 'getFieldsList()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							return response.data.list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getFieldsList()::axios', error);

					});
			},
			getSources() { // @todo: need to remove, implement in `CommonDataService`

				let _this = this;
				return api.get('/common/sources', {
					params: {}
				})
				.then((response) => {

					CommonService.log('debug', 'getSources()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, source]) => {
								list.push({
									value: source.id.toString(),
									name: source.name
								});
							});
						}

						CommonService.log('info', 'AddRequisition::getSources()', list);

						if (typeof _this.$store !== "undefined")
							_this.$store.commit('setSourcesList', list);

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getSources()::axios', error);

				});
			},
			getTypes(section) {
				return api.get('/common/types', {
					params: {
						section: section
					}
				})
					.then((response) => {

						CommonService.log('debug', 'getTypes()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								if(section == 'requisitions' && option.archive == 1){
									/*** */
								} else {
									list.push({
										value: option.id,
										name: option.name
									});
								}
							});

							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getTypes()::axios', error);

					});
			},
			getTags() {
				return api.get('/common/tags', {
					params: {
						with_managers: true
					}
				}).then((response) => {

					CommonService.log('debug', 'getTags()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name
							});
						});
						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getTags()::axios', error);

				});
			},
			getPartners() {
				return api.get('/partners/list', {
					params: {
						filters: {
							only_active: 1,
							with_managers: 1
						}
					}
				})
					.then((response) => {

						CommonService.log('debug', 'getPartners()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {

							let list = [];
							if (response.data.list) {
								let groups = (response.data.groups) ? response.data.groups : [];
								Object.entries(response.data.list).forEach(([key, partner]) => {
									let group_id = partner.group_id ?? null;
									list.push({
										value: partner.id.toString(),
										name: partner.name,
										company: (!this.isEmpty(groups[group_id])) ? groups[group_id] : null,
									});
								});
							}

							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getPartners()::axios', error);

					});
			},
			getContractType(type_id) {

				let contract_type = false;
				if (typeof type_id !== "undefined" && !this.isEmpty(this.contract_types)) {
					this.contract_types.forEach((type) => {
						if (type.value == type_id)
							contract_type = type.name;
					});
				}

				return contract_type;
			},
			async getClientsList(clients_ids) {

				let params = {
					filters: {},
					offset: this.clients_offset ?? 0,
					search_query: this.search_query,
					clients_ids: clients_ids ?? null
				};

				this.clients_proccessed = true;

				CommonService.log('debug', 'getClientsList()', params);

				return api.get('/common/clients', {
					params
				}).then((response) => {

					this.clients_proccessed = false;

					CommonService.log('debug', 'getClientsList()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let clients = [];
						Object.entries(response.data.list).forEach(([key, option]) => {

							let client = option.name;

							let contacts = '';
							if (option.phone && option.phone !== '')
								contacts = option.phone;

							if (option.email && contacts !== '')
								contacts = contacts + ', ' + option.email;
							else if (option.email)
								contacts = option.email;

							if (contacts !== '')
								client = client + ' (' + contacts + ')';

							clients.push({
								value: option.id,
								name: client,
								label: option.name,
							});
						});

						return clients;
					}

					return {};

				}).catch(function (error) {

					CommonService.log('error', 'getClientsList()::axios', error);

					this.clients_proccessed = false;
				});
			},
			setNewClientName(client_id, client_name) {

				api.post('/client/change-name', {
					client_id: client_id,
					client_name: client_name,
				}).then((response) => {

					this.clients_proccessed = false;

					CommonService.log('debug', 'setNewClientName()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						this.is_client_edit = false;
						if (!this.isEmpty(response.data.new_name))
							this.client_name = response.data.new_name;

						this.clients_list = [];
						this.getClientsList().then(list => { this.clients_list = [...this.clients_list, ...list] });
						this.getClientsList([client_id]).then(list => { this.clients_list = [...this.clients_list, ...list] });
					}
				}).catch(function (error) {

					CommonService.log('error', 'setNewClientName()::axios', error);

					this.is_client_edit = true;
				});
			},
			setRequisitionTags: function (tags, source_id) {
				if (typeof tags !== "undefined") {

					let _this = this;
					[].forEach.call(_this.requisitions, function (item, key) {
						if (parseInt(item.id) == parseInt(source_id) && parseInt(source_id) !== 0) {
							_this.requisitions[key].tags = tags;
						}
					});
				}

				CommonService.log('debug', 'setRequisitionTags', {tags: tags, source_id: source_id});

			},
			setAndValidate(name, value) {

				let errors = [];
				let parent = null;
				let child = null;
				let parts = name.split('.', 2);
				if (parts.length == 2) {
					parent = parts[0];
					child = parts[1];
				}

				// Проверки перед установкой значения
				if (this.inArray(name, [
					'type_id',
					'heir_type_id',
				])) {

					let client_name = '';
					if (!this.isEmpty(this.client_name))
						client_name = ' для ' + this.client_name;

					switch (parseInt(value)) {
						case 1:
							this.item.name = "Подбор объекта для" + client_name;
							break;

						case 2:
							this.item.name = "Реализация объекта" + client_name;
							break;

						case 3:
							this.item.name = "Другие услуги" + client_name;
							break;

						case 4:
							this.item.name = "Спрос по нашему объекту" + client_name;
							break;

					}

					// Автопоиск по параметрам объекта
					if (value != 4) {
						this.item.autosearch_enabled = 0;
					} else if (!this.isEmpty(this.item.id)) {
						if (this.item.autosearch.is_reverse == 1) {
							this.item.autosearch_enabled = 1;
						} else {
							this.item.autosearch_enabled = 0;
						}
					} else if (this.isEmpty(this.item.id) && value == 4) {
						this.item.autosearch_enabled = 1;
					}

					// Изменение имени для кастомных типов
					if (!this.isEmpty(this.types_list)) {
						this.types_list.forEach((item, index) => {
							if (item.value == parseInt(value)) {
								this.item.name = item.name + client_name;
							}
						});
					}

					CommonService.log('debug', 'this.item.name', this.item.name);

					this.is_client_edit = false;

					if (this.isEmpty(this.objects_list) && this.inArray(value, [2, 3, 4])) {
						if (!this.isEmpty(this.item.object_id))
							this.getObjectsList([this.item.object_id]).then(list => this.objects_list = list);
						else
							this.getObjectsList().then(list => this.objects_list = list);
					}
				}

				// Валидация значения
				const field = Array.isArray(this.$refs[name]) ? this.$refs[name][0] : this.$refs[name]
				
				if (field && typeof field !== "undefined") {

					if (field.inputLabel) {
						let label = field.inputLabel.replace(/:+$/, '');

						let is_skip = false;
						if (name == 'funnel_id' && value == 0)
							is_skip = true;

						if (field.required && !is_skip) {
							if (name == 'client_id' && value === 0) return;
							if (value) {
								if (value.toString() == '0' || value.toString() == '-1')
									errors.push('Поле `' + label + '` обязательно к заполнению!');
								else if (this.isEmpty(value))
									errors.push('Поле `' + label + '` обязательно к заполнению!');
							} else {
								errors.push('Поле `' + label + '` обязательно к заполнению!');
							}
						}

						if (!this.isEmpty(errors)) {
							this.errors[name] = {
								name: name,
								label: label,
								errors: errors
							};

							field.valid = false;
							field.invalid = errors.join('<br/>');
						} else {
							this.errors[name] = null;
							field.valid = true;
							field.invalid = false;
						}
					}
				}

				// Установка значения
				if (parent && child) {
					if (!this.isEmpty(this.item[parent])) {
						if (typeof (this.item[parent][child]) !== "undefined") {
							this.item[parent][child] = value;
						} else {
							this.item[parent] = {
								...{
									[child]: value
								},
								...this.item[parent]
							};
						}
					} else {
						this.item[parent] = {
							[child]: value
						};
					}
				} else if (parent == null && name) {
					this.item[name] = value;
				} else {
					if (parent && child)
						this.item[parent][child] = null;
					else
						this.item[name] = null;
				}

				this.is_form_changed = true;

				CommonService.log('debug', 'setAndValidate()', { name: name, value: value, is_form_changed: this.is_form_changed, errors: errors });

				if (!this.is_client_edit && name == 'client_id' && !this.isEmpty(value)) {
					CommonService.debounce(this.setAndValidate('type_id', this.item.type_id), 1000);
				}

				if (!this.isEmpty(errors))
					return false;

				return true;
			},
			async getFieldInput(filter_id, field_id) {
				api.get('/autosearch/field-input', {
					params: {
						filter_id: filter_id || null,
						field_id: field_id || null
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getFieldInput()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						this.deBounce(() => {
							this.item.autosearch.filter[field_id]['is_edited'] = true;
							this.item.autosearch.filter[field_id]['input'] = response.data.input;
						});
					}

				}).catch(function (error) {

					CommonService.log('error', 'getFieldInput()::axios', error);

				});
			},
			saveFieldInput(filter_id, field_id, field_value) {
				api.post('/autosearch/field-input', {
					filter_id: filter_id || null,
					field_id: field_id || null,
					field_value: field_value || null
				})
				.then((response) => {

					CommonService.log('debug', 'getFieldInput()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						this.deBounce(() => {

							if (!this.isEmpty(response.data.input[field_id]))
								this.item.autosearch.filter[field_id] = response.data.input[field_id];

							this.item.autosearch.filter[field_id]['is_edited'] = false;
						});
					}

				}).catch(function (error) {

				CommonService.log('error', 'getFieldInput()::axios', error);

				});
			},
			deleteFieldInput(filter_id, field_id) {
				if (confirm('Вы действительно желаете удалить этот параметр?')) {
					api.delete('/autosearch/field-input', {
						params: {
							filter_id: filter_id || null,
							field_id: field_id || null
						}
					})
					.then((response) => {

						CommonService.log('debug', 'deleteFieldInput()::axios', {response: response.data});

						if (response.status == 200 && response.data.success)
							delete this.item.autosearch.filter[field_id];

					}).catch(function (error) {

					CommonService.log('error', 'deleteFieldInput()::axios', error);

					});
				}
			},
			validateAll(fields) {

				this.errors = [];
				let is_valid = false;
				let collection = CommonService.proxyToObject(fields);
				let invalid_input = null;
				let blocks = [];

				for (const [name, value] of Object.entries(collection)) {

					let input_is_valid = true;
					if (typeof name !== "undefined") {
						if (name == 'fields' && !this.isEmpty(this.fields_list)) {

							let entries = [];
							if (!this.isEmpty(value))
								entries = Object.entries(value);

							for (const [field_name, field_value] of entries) {
								if (!this.setAndValidate('fields.' + field_name, field_value, true)) {
									input_is_valid = false;
								}
							}
						} else if (this.inArray(name, blocks)) {
							blocks.forEach((block_name) => {
								if (name == block_name) {

									let entries = [];
									if (!this.isEmpty(value))
										entries = Object.entries(value);

									for (const [field_name, field_value] of entries) {
										if (!this.setAndValidate(block_name + '.' + field_name, field_value, true)) {
											input_is_valid = false;
										}
									}
								}
							});
						} else {
							if (!this.setAndValidate(name, value, true)) {
								input_is_valid = false;
							}
						}
					}

					CommonService.log('log', 'Validate: ' + name + ', is valid? - ' + input_is_valid.toString());

				}

				if (typeof Object.values(this.errors) == "object") {
					this.errors = CommonService.removeEmpty(Object.values(this.errors));
					//this.errors = CommonService.resetArray(Object.values(this.errors));
					this.errors = Object.values(this.errors);
					if (typeof (this.errors[0]) !== "undefined") {
						if (!this.isEmpty(this.errors[0].name)) {
							let invalid_input = this.errors[0].name;
							if (invalid_input && typeof invalid_input !== "undefined") {
								let input = Array.isArray(this.$refs[invalid_input]) ? this.$refs[invalid_input][0].$el : this.$refs[invalid_input].$el;
								const inputes = Object.values(this.errors).map(error => (Array.isArray(this.$refs[error.name]) ? this.$refs[error.name][0] : this.$refs[error.name]).$el)
								if (input) {
									this.$nextTick(() => CommonService.scrollIntoMaxTopView(inputes, 70));
								}
							}
						}
					}
				}

				is_valid = this.isEmpty(this.errors);

				CommonService.log('debug', 'validateAll()', {fields: collection, is_valid: is_valid, errors: this.errors});

				return is_valid;
			},
			addEditRequisition() {

				CommonService.log('debug', 'addEditRequisition()', this.item);

				this.item.is_hot = Boolean(this.item.is_hot);
				this.item.priority = Number(this.item.priority);

				if (!this.isEmpty(this.funnels_list[0])) {
					if (this.item.funnel_id == 0 && this.funnels_list[0].value !== 0) {
						this.item.funnel_id = this.funnels_list[0].value;
					}
				}

				if (this.validateAll(this.item)) {

					this.is_form_changed = false;
					api.post('/requisition/add', this.item).then((response) => {

						if (process.env.NODE_ENV == "development")
							console.debug('addEditRequisition()::axios', {
								response: response.data
							});

						if (response.status == 200 && response.data.success) {

							this.$store.commit('resetFormsState', 'addRequisition');
							this.resultsModalState.state = 'success';

							if (this.item.id)
								this.resultsModalState.title = 'Заявка была успешно сохранёна!';
							else
								this.resultsModalState.title = 'Новая заявка была успешно добавлена!';

							if (!this.isEmpty(response.data.requisition_id))
								this.item.id = response.data.requisition_id;

						} else {
							this.resultsModalState.state = 'error';

							if (this.item.id)
								this.resultsModalState.title = 'Ошибка сохранения заявки.';
							else
								this.resultsModalState.title = 'Ошибка добавления новой заявки.';

							if (!this.isEmpty(response.data.errors))
								this.resultsModalState.message = response.data.errors.join('<br/>');

						}

					}).catch(function (error) {

						CommonService.log('error', 'addEditRequisition()::axios', error);

					});

					this.resultsModalState.state = '';
					this.resultsModalState.title = '';
					this.resultsModalState.message = '';
				}
			},
			getCustomFieldValue(key) {

				if (this.item.fields && !this.isEmpty(key) && !this.isEmpty(this.item.fields))
					return this.item.fields[key];

				return null;
			},
		}, CommonDataService.methods),
		mounted() {

			this.getRequiredTypeRole().then(data => {
				this.required_type = data.required_type;
				this.required_role = data.required_role;
				this.required_source = data.required_source;
			});

			if (this.requisition_id !== null) {

				CommonService.setPageTitle(this.title['edit']);

				if (location.href.indexOf("#objects") != -1) {
					this.form_step = 2; // Объекты
				} else if (location.href.indexOf("#autosearch") != -1) {
					this.form_step = 3; // Параметры автописка
				}

				if (this.restore_form) {

					let stored = this.$store.getters.formState['addRequisition'];
					CommonService.log('debug', 'getFormState', stored);

					if (typeof stored !== "undefined")
						this.item = stored;

						this.old_employee_id = this.item.employee_id
						this.old_is_confirm = this.item.is_confirm
						

				} else {

					this.$store.commit('resetFormsState', 'addRequisition');

					this.getRequisition(this.requisition_id).then((data) => {

						this.item = {...this.item, ...data};

						this.old_employee_id = this.item.employee_id
						this.old_is_confirm = this.item.is_confirm

						if (!this.isEmpty(this.item.client_id)) {
							this.getClient(this.item.client_id).then((client) => {
								this.client = client;
								this.client_id = client.id;
							});
							this.getClientsList([this.item.client_id]).then(list => { this.clients_list = [...this.clients_list, ...list] });
						}

						if (!this.isEmpty(this.item.fields)) {
							this.fields_list = this.item.fields;
							this.item.fields = {};
							Object.entries(this.fields_list).forEach(([key, option]) => {
								this.item.fields[key.toString()] = option != null ? option.value : null;
							});
						}


						if (!this.isEmpty(this.item.object_id) && (this.inArray(this.item.type_id, [2, 3, 4]) || this.inArray(this.item.heir_type_id, [2, 3, 4]))) {
							this.getObjectsList([this.item.object_id]).then(list => this.objects_list = list);
						} else {
							this.getObjectsList().then(list => { this.objects_list = [...this.objects_list, ...list] });
						}

						if (this.item.autosearch.is_reverse == 1 && (this.inArray(this.item.type_id, [4]) || this.inArray(this.item.heir_type_id, [4]))) {
							this.item.autosearch_enabled = 1;
						}

						this.is_loading_process = false;
					});
				}

			} else {

				CommonService.setPageTitle(this.title['default']);

				if (this.restore_form) {

					let stored = this.$store.getters.formState['addRequisition'];
					CommonService.log('debug', 'getFormState', stored);

					if (typeof stored !== "undefined")
						this.item = stored;

						this.old_employee_id = this.item.employee_id
						this.old_is_confirm = this.item.is_confirm

				} else {
					this.$store.commit('resetFormsState', 'addRequisition');
				}

				if (this.type_id) {
					this.item.type_id = this.type_id;
				}

				/*if (this.object_id && this.type_id) {
					if (!this.isEmpty(this.item.object_id) && (this.inArray(this.item.type_id, [2, 3, 4]) || this.inArray(this.item.heir_type_id, [2, 3, 4]))) {
						this.setAndValidate('object_id', this.object_id);
						CommonService.debounce(this.setAndValidate('object_id', this.object_id), 2000);
					}
				}*/

				if (this.funnel_id) {
					this.item.funnel_id = this.funnel_id;
				} else {
					this.item.funnel_id = 0;
				}

				if (this.funnel_id == 0)
					this.item.stage_id = 1;

				this.item.employee_id = this.$store.getters.userInfo.id;

				if (this.inArray(this.item.type_id, [4]) || this.inArray(this.item.heir_type_id, [4])) {
					this.item.autosearch_enabled = 1;
				}

				this.getFieldsList('requisitions').then(data => {
					this.fields_list = data;
					if (!this.isEmpty(this.fields_list)) {
						this.item.fields = {};
						Object.entries(this.fields_list).forEach(([key, option]) => {
							console.log(option, 'optionoptionoption');
							
							if (!this.isEmpty(key))
								this.item.fields[key.toString()] = option != null ? option.value : null;
						});
					}
				});

				this.is_loading_process = false;
			}

			this.getClientsList().then(list => { this.clients_list = [...this.clients_list, ...list] });

			if (this.isEmpty(this.funnels_list))
				this.getFunnels().then((list) => this.funnels_list = list);

			if (this.isEmpty(this.types_list))
				this.getTypes('requisitions').then((list) => this.types_list = list);

			if (this.isEmpty(this.sources_list))
				this.getSources().then((list) => this.sources_list = list);

			if (this.isEmpty(this.partners_list))
				this.getPartners().then((list) => this.partners_list = list);

			if (this.isEmpty(this.tags_list))
				this.getTags().then((list) => this.tags_list = list);

			if (this.isEmpty(this.employees_list))
				this.getEmployees().then(list => this.employees_list = list);

			if (this.isEmpty(this.fields_list))
				this.getFieldsList().then(list => this.fields_list = list);

			if (this.isEmpty(this.contract_types))
				this.getTypes('contract_types').then(data => { this.contract_types = data });

			if (this.object_id && this.type_id) {
				this.setAndValidate('object_id', this.object_id);
				if (!this.isEmpty(this.item.object_id) && (this.inArray(this.item.type_id, [2, 3, 4]) || this.inArray(this.item.heir_type_id, [2, 3, 4]))) {
					this.getObjectsList([this.item.object_id]).then(list => {
						this.objects_list = list;
					});
				}
			} else {
				if (this.inArray(this.item.type_id, [2, 3, 4]) || this.inArray(this.item.heir_type_id, [2, 3, 4])) {
					this.getObjectsList().then(list => this.objects_list = list);
				}
			}

			this.emitter.on('autoSearchFilterAdded', (data) => {

				if (!this.isEmpty(data))
					this.item.autosearch_id = data.filter_id;

				if (process.env.NODE_ENV == "development") {
					console.debug('Fire `autoSearchFilterAdded`', {
						filter_id: data.filter_id,
						autosearch_id: this.item.autosearch_id
					});
				}
			});
		},
		watch: {
			'item.employee_id'(value, oldValue) {
				if (!oldValue) return;

				if (this.old_employee_id && value) {
					this.item.is_confirm = value !== this.old_employee_id ? false : this.old_is_confirm
				}
			},
			requisition_id: function() {

				this.requisition_id = this.requisitionId ?? null;

				CommonService.log('debug', 'watch::requisition_id', this.requisition_id);

				return this.requisition_id;
			},
			search_query(value, oldValue) {
				//if (((CommonService.strpos(value, oldValue) === -1 || this.isEmpty(value)) && (value.length >= 3 || oldValue.length >= 3)) || value.length == 0) {
				if ((value.length >= 3 || oldValue.length >= 3) || value.length == 0) {
					CommonService.debounce(() => this.getClientsList().then(data => {
						this.clients_list = data;
					}), 1600);
				}
			},
			objects_search_query(value, oldValue) {
				//if (((CommonService.strpos(value, oldValue) === -1 || this.isEmpty(value)) && (value.length >= 3 || oldValue.length >= 3)) || value.length == 0) {
				if ((value.length >= 3 || oldValue.length >= 3) || value.length == 0) {
					CommonService.debounce(() => this.getObjectsList().then(data => {
						this.objects_list = data;
					}), 1600);
				}
			},
			clients_offset(value, oldValue) {
				if (value !== oldValue && value != 0) {
					CommonService.debounce(() => this.getClientsList().then(data => {
						this.clients_list = [...this.clients_list, ...data];
					}), 1600);
				}
			},
			objects_offset(value, oldValue) {
				if (value !== oldValue && value != 0) {
					CommonService.debounce(() => this.getObjectsList().then(data => {
						this.objects_list = [...this.objects_list, ...data];
					}), 1600);
				}
			}
		},
		computed: {
			user_can() {
				return {
					autosearch: {
						edit_search: this.$store.getters.userInfo.user_can.autosearch && (this.item.type_id == 1 || this.item.heir_type_id == 1),
						edit_reverse: this.$store.getters.userInfo.user_can.autosearch && (this.item.autosearch.is_reverse == 1) && (this.item.type_id == 4 || this.item.heir_type_id == 4),
						view: (this.item.autosearch.error || this.item.autosearch.active > 0 || this.item.autosearch.count > 0) && (this.item.type_id == 1 || this.item.heir_type_id == 1),
					}
				};
			},
			checkedTags() {
				return this.tagList.filter(tag => tag.isChecked)
			},
			client() {

				if (!this.isEmpty(this.clients_list) && !this.isEmpty(this.item.client_id))
					return this.clients_list.filter(client => client.value == this.item.client_id);
				
				return null;
			},
			form_steps() {

				let steps = [
					{
						id: 1,
						title: 'Общие',
						icon: null,
					}
				];

				if (this.inArray(this.item.type_id, [2, 3, 4]) || this.inArray(this.item.heir_type_id, [2, 3, 4])) {
					steps.push({
						id: 2,
						title: 'Объекты',
						icon: null,
					});
				}

				if (!this.isEmpty(this.item.autosearch.filter) && this.inArray(this.item.type_id, [1, 4]) || this.inArray(this.item.heir_type_id, [1, 4])) {
					steps.push({
						id: 3,
						title: 'Параметры автопоиска',
						icon: null,
					});
				}

				return steps;
			},
			client_name: {
				get() {
					return (!this.isEmpty(this.client)) ? this.client[0].label : '';
				},
				set(value) {
					value = value.trim();
				}
			},
			type_id: {
				get() {

					let type_id = null;
					if (typeof this.$props.typeId !== "undefined")
						type_id = this.$props.typeId;

					if (!this.isEmpty(this.item.type_id))
						type_id = this.item.type_id;

					if (!this.isEmpty(this.item.heir_type_id))
						type_id = this.item.heir_type_id;

					return type_id;
				},
				set(value) {
					value = value.trim();
				}
			},
		},
    };
</script>

<style lang="scss">
	.add-requisition {
		.step-list {
			@media(max-width: 480px) {
				flex-direction: column;
				align-items: flex-start !important;

				li:not(:last-child) {
					margin-bottom: 20px;
				}
			}
		}
	}
</style>